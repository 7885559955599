import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import client from '../../modules/client'
import VcardField from 'piconetworks/pkg-field-vcard'

import logger from 'lib/logger'

const VcardBlock = ({ children }) => {
    const publisherId = useSelector(client.selectors.clientId)
    const vCardUrl = `https://files.hype.co/${process.env.STAGE}/${publisherId}/vcards/${publisherId}.vcf`

    const fetchVcard = useCallback(async (vCardUrl) => {
        let vcfString = ''

        try {
            const res = await fetch(vCardUrl)
            vcfString = await res.text()

            const blob = new Blob([vcfString], {type: 'text/x-vcard'})
            const downloadUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = downloadUrl
            a.download = `${publisherId}.vcf`
            document.body.appendChild(a)
            a.click()
        } catch (error) {
            logger.error('fetchVcard', {}, error)
        }

        return vcfString
    }, [publisherId])

    const onClick = useCallback((e) => {
        fetchVcard(vCardUrl)
    }, [fetchVcard, vCardUrl])

    return (
        <VcardField
            onClick={onClick}
        />
    )
}

export default VcardBlock
