import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Utils from 'modules/utils'
import OneTimePasscode from 'piconetworks/pkg-otp'
import observers from '../../lib/observers'
import Account from 'modules/account'
import Client from 'modules/client'
import styles from './style.module.scss'
import cx from 'classnames'
import Router from 'next/router'

import logger from 'lib/logger'

const CrossVerification = () => {
    const [currentPasscode, setCurrentPasscode] = useState('')
    const dispatch = useDispatch()

    const email = useSelector(Utils.selectors.getVerificationEmail)
    const phone = useSelector(Utils.selectors.getVerificationPhone)
    const oneTimeCodeError = useSelector(Account.selectors.crossVerificationError)
    const publisherId = useSelector(Client.selectors.clientId)
    const publisherName = useSelector(Client.selectors.name)


    const processCode = useCallback(async () => {
        try {
            const { oneTimeCodeConfirmed } = await observers.crossVerification({
                dispatch,
                payload: {
                    code: currentPasscode,
                    ...(email && { email }),
                    ...(phone && { phone }),
                    origin,
                    publisherId,
                    isLogin: true,
                },
            })
            if (oneTimeCodeConfirmed) {
                Router.reload(window.location.pathname)
            }

        } catch (error) {
            logger.error('Error in cross verification', {}, error)
        }
    }, [currentPasscode, email, phone, publisherId, dispatch])

    useEffect(() => {
        if (currentPasscode.length === 6) {
            processCode()
        }
    }, [currentPasscode, processCode])

    return (
        <div className={cx('block', styles.cross)}>
            <div>
                <div className='mb-3 text-center'>
                    <label className='text-picoblack text-opacity-70 text-sm font-normal '>
                        {publisherName} has added you to their list. Enter the verification code sent to {email || phone} to confirm your contact information.
                    </label>
                </div>
                <div className='d-flex justify-content-center'>
                    <OneTimePasscode
                        isErrored={false}
                        isInputNum={true}
                        className='otp'
                        shouldAutoFocus={true}
                        numInputs={6}
                        currentPasscode={currentPasscode}
                        setCurrentPasscode={setCurrentPasscode}
                        errorText={'Something went wrong'}
                    />
                </div>
            </div>
            <div className='mt-3' >
                <p className='text-xs text-gray-700 font-medium'>
                    Didn&apos;t receive a code? You can safely close this window.
                </p>

            </div>
            {oneTimeCodeError && (
                <div className="mt-4 text-sm text-red-500" role="alert">
                    <span className="font-medium">{oneTimeCodeError.error}</span>
                </div>
            )}
        </div>
    )

}

export default CrossVerification
