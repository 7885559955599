import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Picon from 'piconetworks/pkg-picon'

import LandingPage from 'modules/landing-page'
import Client from 'modules/client'

import styles from './OfferCodeBlock.module.scss'

const OfferCodeBlock = () => {
    const dispatch = useDispatch()

    const showOfferCode = useSelector(LandingPage.selectors.showOfferCode)
    const publisherUsername = useSelector(Client.selectors.username)
    const publisherId = useSelector(Client.selectors.clientId)
    const fetchedLandingPage = useSelector(LandingPage.selectors.fetchedLandingPage)
    const fetchLandingPageError = useSelector(LandingPage.selectors.fetchLandingPageError)

    const [requestedOfferCode, setRequestedOfferCode] = useState(null)
    const [isOfferCodeVisible, setIsOfferCodeVisible] = useState(false)

    const retrievedLandingPage = fetchedLandingPage?.[requestedOfferCode]

    const onSubmit = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()

        const shortCode = e?.target?.elements?.offerCode?.value

        setRequestedOfferCode(shortCode)

        dispatch(LandingPage.creators.fetchLandingPage({
            payload: {
                shortCode,
                publisherId,
                publisherUsername
            },
        }))
    }, [
        dispatch,
        publisherId,
        publisherUsername,
        setRequestedOfferCode,
    ])

    useEffect(() => {
        if (!publisherId || !isEmpty(showOfferCode)) {
            return
        }

        dispatch(LandingPage.creators.checkIfShowOfferCode({
            payload: {
                publisherId,
            },
        }))
    }, [publisherId, showOfferCode, dispatch])

    useEffect(() => {
        if (retrievedLandingPage?.short_code) {
            window.location.href = `/@${publisherUsername}/${retrievedLandingPage?.short_code}`
        }
    }, [retrievedLandingPage?.short_code, publisherUsername])

    if (!showOfferCode) {
        return null
    }

    return (
        <div className={`${styles.offerCodeBlock}`}>
            {!isOfferCodeVisible && <button className={`${styles.offerCodeToggle}`} onClick={() => setIsOfferCodeVisible(true)}>Enter offer code</button>}
            {isOfferCodeVisible && <form onSubmit={onSubmit}>
                <label><strong>Offer Code</strong></label><br />
                <input
                    type="text"
                    name="offerCode"
                />
                <button
                    className={`btn ${styles.offerCodeBlockButton}`}
                    type="submit"
                ><Picon
                    icon='arrow-right'
                    color='white'
                    size='25'
                    /></button>
                {fetchLandingPageError && (
                    <div className={styles.offerCodeBlockError}>
                        Unable To Find Offer Code
                    </div>
                )}
            </form>}
        </div>
    )
}

export default OfferCodeBlock
