import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'lib/RouterAdapterDecorator'
import { Link } from 'lib/RouterAdapterDecorator'

import { P } from 'piconetworks/pkg-text'
import Boutton from 'piconetworks/pkg-boutton'

import style from './style.module.scss'
import AppContainerContext from '../AppContainer/AppContainerContext'
import { GO_TO_MENU_ROUTE_AND_OPEN } from 'piconetworks/pkg-menu-middleware'
import { useDispatch, useSelector } from 'react-redux'
import LandingPage from 'modules/landing-page'

const LoginHeader = (props) => {
    const dispatch = useDispatch()

    const {
        isPayingText,
        router,
        showLoginPrompt,
    } = props
    const { setUseCustomPrompt, showLoginButton, isReturningUser, setIsReturningUser } = useContext(AppContainerContext)

    const { query } = router
    const { creator_username, short_code, id: wizardName } = query

    const landingPage = useSelector((state) => LandingPage.selectors.getLandingPageByShortCode(state, short_code))

    const isPaymentLP = (landingPage?.type || '').includes('payment')

    if (!showLoginButton) {
        return null
    }

    const promptText = isPayingText ? 'Already paying?' : isReturningUser ? "Don't have an account yet?" : 'Already signed up?'

    const isLoginWizard = wizardName === `LoginWizard_${creator_username}`

    return (
        <div className={style.loginContainer}>
            {showLoginPrompt && (
                <P
                    className={style.loginPrompt}
                >
                    {promptText}
                </P>
            )}
            {showLoginButton && (
                <P className={style.loginButton}>
                    {!isPaymentLP ? (
                        <span onClick={() => {
                            dispatch({
                                type: GO_TO_MENU_ROUTE_AND_OPEN,
                                payload: {
                                    menuRoute: '/login'
                                },
                            })
                            window.scrollTo(0, 0)
                        }}>
                            <Boutton
                                variant="link"
                                size="sm"
                                onClick={() => {
                                    setUseCustomPrompt && setUseCustomPrompt(false)
                                }}
                            >
                                <span>{ isReturningUser ? 'Sign up' : 'Log in' }</span>
                            </Boutton>
                        </span>
                    ) : (
                        <Link
                            href={{
                                pathname: '/login',
                                query: {
                                    ...(short_code && { short_code }),
                                    ...(wizardName
                                        && !isLoginWizard && {
                                        id: wizardName,
                                        asPath: router.asPath,
                                        pathname: router.pathname,
                                    }),
                                    isRegister: false,
                                },
                            }}
                            as={{
                                pathname: `/login`,
                                query: {
                                    ...(short_code && { short_code }),
                                    ...(wizardName && !isLoginWizard && {
                                        id: wizardName,
                                        asPath: router.asPath,
                                        pathname: router.pathname,
                                    }),
                                    isRegister: false,
                                },
                            }}
                        >
                            <Boutton
                                variant="link"
                                size="sm"
                                className={style.loginButton}
                                onClick={() => {
                                    setUseCustomPrompt && setUseCustomPrompt(false)
                                    setIsReturningUser(!isReturningUser)
                                }}
                            >
                                <span>{ isReturningUser ? 'Sign up' : 'Log in' }</span>
                            </Boutton>
                        </Link>
                    )}

                </P>
            )}
        </div>
    )
}

LoginHeader.defaultProps = {
    isPayingText: false
}

LoginHeader.propTypes = {
    isPayingText: PropTypes.bool,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
            id: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
}

export default withRouter(LoginHeader)
