import React, { useCallback } from 'react'

import Picon from 'piconetworks/pkg-picon'

import styles from './Vcard.module.scss'

const DefaultContactInfo = () => (
    <div>
        <Picon icon="icon_save_to_phone" size='24' color="#120A20" />
        <h3>Save contact info</h3>
    </div>
)

const VcardBlock = ({
    children,
    onClick: _onClick = () => {},
    className: _className = '',
}) => {
    const onClick = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()

        _onClick()
    }, [])

    return (
        <div ontouchstart="" className={`${styles.vcard} block`}>
            <a className={`${styles.link}`} href={'#'} target='_blank' onClick={onClick} rel="noreferrer">{children || (<DefaultContactInfo />)}</a>
        </div>
    )
}

export default VcardBlock
