/* eslint-disable */
import React, { useRef, useEffect, useContext, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormSpy } from 'react-final-form'
import cx from 'classnames'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'

import Account from 'modules/account'
import LandingPage from 'modules/landing-page'
import Me from 'modules/me'
import Metrics from 'modules/metrics'
import Newsletter from 'modules/newsletter'
import Blocks from 'modules/blocks'
import Wizard from 'modules/wizard'
import Utils from 'modules/utils'

import IframelyModule from 'modules/iframely'
import FeatureFlags from 'modules/feature-flags'

import LoginLink from '../LoginLink/LoginLink'

import Markdown from 'wrappers/Markdown'
import getFields, { isCustomFieldsValid } from 'lib/CartHelper/getFields'
import WizardSlider from 'piconetworks/pkg-wizard-slider'
import WizardRedux from 'pkgs/WizardRedux'
import AppContainerContext from '../AppContainer/AppContainerContext'

import styles from './style.module.scss'
import orderBy from 'lodash/orderBy'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { getOrderType } from '../../lib/CartHelper'
import OfferCodeBlock from './OfferCodeBlock'
import CrossVerification from './CrossVerification'
import VcardBlock from '../VcardBlock'
import { useRouter } from 'next/router'

const Product = (block) => {
    const router = useRouter()

    const _getOutboundClaim = useSelector((state) => state?.claim?.outboundClaim)
    const isPageConsolidation = useSelector((state) => FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'page-consolidation'))

    const product = block?.product_block?.product
    const order = getOrderType({ product })
    const Selection = order?.Selection || (() => null)
    const { query = {} } = router

    const {
        conv_id
    } = query

    const _onSubmit = useCallback((values) => {
        const productOptionId = values?.productOptionId
        const customPrice = values?.customPrice
        const redirectString = qs.stringify({
            ...(_getOutboundClaim && { claim: _getOutboundClaim }),
            ...(customPrice && { custom_price: customPrice }),
            ...(conv_id && { conv_id })
        })
        const questionMark = redirectString ? '?' : ''
        const newLocation = `${process.env.CHECKOUT_APP_URL}/${productOptionId}${questionMark}${redirectString}`

        window.location.href = newLocation
    }, [
        _getOutboundClaim,
    ])

    const debouncedOnSubmit = debounce(_onSubmit, 100)

    if (!isPageConsolidation) {
        return null
    }

    return (
        <Form
            mutators={{
                setProductOptionId: (args, state, utils) => {
                    utils.changeValue(state, 'productOptionId', () => args[0])
                },
                setCustomPrice: (args, state, utils) => {
                    utils.changeValue(state, 'customPrice', () => args[0])
                },
            }}

            render={({ form }) => (
                <>
                    <FormSpy
                        subscription={{ values: true }}
                        onChange={props => {
                            if (props.values?.productOptionId) {
                                form.submit()
                            }
                        }}
                    />
                    <Selection
                        product={product}
                        setCustomPrice={form.mutators.setCustomPrice}
                        setProductOptionId={form.mutators.setProductOptionId}
                        onChangePatch={true}
                        onChange={() => ({})}
                    />
                </>
            )}
            onSubmit={debouncedOnSubmit}
        />
    )

}

const EnterEmail = ({
    authenticationMethods,
    currentUser,
    backButton = {},
    landingPage,
    isJoiningGroup,
    onAccountCreated,
    onLogin,
    onSubmitCallback,
    onLinkClick,
    onSkipOtp,
    isSignupLandingPage,
    isPaymentLandingPage,
    onAuthenticated = () => { },
    hideLinkBlocks = false,
}) => {
    const [flow, setFlow] = useState(null)
    const [errorMessage, setErrorMessage] = useState({})
    const isPageConsolidation = useSelector((state) => FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'page-consolidation'))
    const dispatch = useDispatch()

    const hasTheme = landingPage?.theme_id
    const hasCustomizations = hasTheme || !isEmpty(landingPage?.form_customizations)

    let blockContrastColor = {
        value: '#FFFFFF',
    }
    let overriddenContrastColor = null
    if (!isEmpty(landingPage?.form_customizations)) {
        blockContrastColor = landingPage?.form_customizations?.find((p) => p.property === 'block-contrast-color')
    } else if (!isEmpty(landingPage?.theme_customizations)) {
        blockContrastColor = landingPage?.theme_customizations?.find((p) => p.property === 'block-contrast-color')
    }
    overriddenContrastColor = blockContrastColor?.value

    // If page has theme, linkColor should be pageContrastColor?.value
    // to render newsletter checkboxes.
    const linkColor = hasCustomizations ? overriddenContrastColor : blockContrastColor?.value

    const landingPageFields = useSelector(() => LandingPage.selectors.getMappedFields(landingPage?.fields), isEqual)
    const _blocks = useSelector((state) => Blocks.selectors.getBlocksByFormId(state, landingPage?.id), isEqual)

    const blocks = _blocks.map((block) => {
        if (block.type === 'link') {
            return {
                ...block,
                link_block: {
                    ...block.link_block,
                    links: block.link_block.links.map((link) => {
                        return {
                            ...link,
                            url: link.is_rich 
                                ? link.url 
                                : link.short_link || link.url,
                        }
                    }),
                },
            }
        }

        return block
    })

    const signup_block = blocks?.find((block) => block.type === 'signup')?.signup_block

    const freeNewsletters = useSelector(Newsletter.selectors.freeNewsletters, isEqual)

    const richMediaUrls = useSelector(IframelyModule.selectors.getAllRichMedia, isEqual)
    const publisherAuthenticationMethods = useSelector(LandingPage.selectors.publisherAuthenticationMethods)
    const defaultPublisherAuthenticationMethod = useSelector(LandingPage.selectors.defaultPublisherAuthenticationMethod)

    const customFields = getFields(landingPageFields, 'customFields')

    const {
        isReturningUser,
        readyToPage,
        focusCreateAcccount,
        isPreview,
        previewHandler
    } = useContext(AppContainerContext)

    const landingPageNewsletters = landingPage?.newsletters || []
    const preSelectedNewsletters = landingPageNewsletters?.map(({ id }) => id) || []
    const shouldRenderBackButton = backButton.shouldRender

    const isEmailShared = useSelector(Me.selectors.userEmailIsShared, isEqual)
    const isPhoneShared = useSelector(Me.selectors.userPhoneIsShared, isEqual)
    const oneTimeCodeSent = useSelector(Account.selectors.oneTimeCodeSent, isEqual)
    const _getVerificationProgress = useSelector(Utils.selectors.getVerificationProgress)

    const checkedFreeNewsletters = freeNewsletters.map((newsletter) => {
        const shouldBeChecked = preSelectedNewsletters.includes(newsletter.id)
        return {
            ...newsletter,
            checked: shouldBeChecked
        }
    })



    // due to rerendering issues with this component remounting,
    // this effect is made to only run once through the use of context `setReadyToPage`
    useEffect(() => {
        if (readyToPage) {
            if (!currentUser) {
                dispatch(Metrics.creators.sendMessage({
                    payload: {
                        promptType: landingPage?.type,
                        triggerId: landingPage?.id
                    }
                }))
            }
        }
    }, [landingPage, readyToPage])

    const onPasswordLogin = useCallback(({ selectedNewsletters, customProperties, company_slug }) => {
        const creator_username = company_slug.replace(/^@/, '')
        dispatch(
            Wizard.creators.updatePayload({
                payload: {
                    id: `LoginWizard_${creator_username}`,
                    payload: {
                        selectedNewsletters: selectedNewsletters,
                        customProperties: customProperties,
                    }
                }
            })
        )
    }, [dispatch])

    const signup = getFields([{
        id: 'signup',
        description: 'signup',
        type: 'Signup',
        required: false,
        order: 15,
    }])

    const links = getFields([{
        id: 'link',
        title: 'Links',
        description: 'Custom Links',
        type: 'Link',
        required: false,
        order: 15,
    }])

    const renderBackButtonText = {
        title: 'Create an account',
        subtitle: `Continue below to ${isJoiningGroup
            ? 'join'
            : 'complete your payment'
            }.`,
    }

    const wizardSliderText = shouldRenderBackButton && !isSignupLandingPage
        ? renderBackButtonText
        : {}

    const disabledSignup = isSignupLandingPage
        ? !blocks.some(block =>
            block.type === 'signup' && block?.signup_block?.type === 'consolidated'
        )
        : false

    useEffect(() => {
        if ((currentUser)) {
            onAuthenticated()
        }
    }, [
        currentUser,
    ])

    const createAccountRef = useRef(null)

    const scrollToCreateAccount = useCallback(() => {
        createAccountRef?.current?.scrollIntoView?.()
    }, [createAccountRef])

    useEffect(() => {
        if (focusCreateAcccount) {
            scrollToCreateAccount()
        }
    }, [focusCreateAcccount])


    if (_getVerificationProgress) {
        return <CrossVerification />
    }



    return (
        <div className={styles.enterEmail}>
            <WizardSlider
                title={wizardSliderText.title || ''}
                subtitle={wizardSliderText.subtitle || ''}
                wizardHeaderClass="signupFormHeader"
                wizardContentClass="signupFormContentFlex"
                onBackButtonClick={backButton.onClick}
                linkColor={linkColor}
                renderBackButton={shouldRenderBackButton}
            >
                <WizardRedux.Step
                    onSubmit={({ values }) => {
                        const submitWizard = values?.wizardData?.submitWizard

                        if (!submitWizard) {
                            throw new Error('submitWizard not selected')
                        }

                        return {
                            submitWizard,
                        }
                    }}
                    rffProps={{
                        mutators: {
                            setWizardData: (args, state, utils) => {
                                utils.changeValue(state, 'wizardData', () => args[0])
                            }
                        },
                    }}
                    render={() => {
                        const Blocks = {
                            Product: (block) => {
                                if (isPreview) {
                                    return React.cloneElement(<Product />, { ...block })
                                } else {
                                    return <Product {...block} />
                                }
                            },
                            Signup: (block) => {
                                return (
                                    <Form
                                        initialValues={{ newsletters: preSelectedNewsletters }}
                                        keepDirtyOnResubmit={true}
                                        keepDirtyOnReinitialize
                                        onSubmit={() => { return <div /> }}
                                        render={({ values }) => (
                                            <div
                                                ref={createAccountRef}
                                                className={
                                                    cx(styles.signupContainer, styles.signupContainerV2, 'block')
                                                }
                                            >
                                                {!disabledSignup && signup.map((Field, idx) => (
                                                    <Field
                                                        key={idx}
                                                        popupId={
                                                            landingPage?.id
                                                        }
                                                        customFieldsValid={() =>
                                                            landingPageFields?.length
                                                                ? isCustomFieldsValid(
                                                                    landingPageFields,
                                                                    values?.customFields
                                                                )
                                                                : true
                                                        }
                                                        isReturningUser={isReturningUser}
                                                        newsletters={orderBy(checkedFreeNewsletters, ['newsletter_order'], ['asc'])}
                                                        authenticationMethods={isPageConsolidation ? publisherAuthenticationMethods : isPaymentLandingPage && isReturningUser
                                                            ? publisherAuthenticationMethods
                                                            : authenticationMethods
                                                        }
                                                        showError={true}
                                                        values={values}
                                                        linkColor={linkColor}
                                                        showTerms={!oneTimeCodeSent}
                                                        onSubmitCallback={onSubmitCallback}
                                                        onAccountCreated={onAccountCreated}
                                                        title={signup_block?.title || ''}
                                                        subtitle={signup_block?.subtitle || ''}
                                                        onLogin={onLogin}
                                                        onPasswordLogin={onPasswordLogin}
                                                        onSkipOtp={onSkipOtp}
                                                        isPaymentLandingPage={isPaymentLandingPage}
                                                        customFields={customFields}
                                                        defaultType={{
                                                            ...flow,
                                                            type: defaultPublisherAuthenticationMethod
                                                        }}
                                                        setFlow={setFlow}
                                                        isAuthed={{
                                                            email: isEmailShared && !oneTimeCodeSent,
                                                            sms: isPhoneShared && !oneTimeCodeSent
                                                        }}
                                                        errorMessage={errorMessage}
                                                        setErrorMessage={setErrorMessage}
                                                        markDown={() => (
                                                            <Markdown
                                                                url={signup_block?.subtitle}
                                                                className={styles.md_description}
                                                            />
                                                        )}
                                                    >
                                                        {!oneTimeCodeSent
                                                            && (!isEmailShared && !isPhoneShared) && (
                                                                <LoginLink
                                                                    linkColor={
                                                                        linkColor
                                                                    }
                                                                    showLoginPrompt
                                                                />
                                                            )}
                                                    </Field>
                                                ))}
                                            </div>
                                        )}
                                    />
                                )
                            },
                            Link: (block) => (
                                <div className={styles.links}>
                                    {links.map((Field, idx) => (
                                        <Field
                                            key={idx}
                                            linkColor={linkColor}
                                            onLinkClink={onLinkClick}
                                            richMediaData={richMediaUrls}
                                            linkBlocks={block ? [block.link_block] : []}
                                        />
                                    ))}
                                </div>
                            ),
                            Text: (block) => (

                                <div className={cx(styles.markdownBlock, { [styles.hasCustomizations]: hasCustomizations })}>
                                    <Markdown
                                        url={block?.text_block?.file_name}
                                        className={styles.markdown}
                                    />
                                </div>
                            ),
                            Vcard: (block) => (

                                <div className={cx({ [styles.hasCustomizations]: hasCustomizations })}>
                                    <VcardBlock>
                                        Hit the pound to download my vcard!
                                    </VcardBlock>
                                </div>
                            ),
                        }

                        const components = blocks.length
                            ? blocks.reduce((conditions, block, idx) => {
                                let condition = {}
                                switch (block.type) {
                                    case 'product':
                                        condition = {
                                            [`product-${idx}`]: { block_id: block.id, temp_id: block.temp_id, block: Blocks.Product(block) },
                                        }
                                        break
                                    case 'signup':
                                        if (block?.signup_block?.type === 'consolidated') {
                                            condition = {
                                                signup: { block_id: block.id, temp_id: block.temp_id, block: Blocks.Signup(block) },
                                            }
                                        }
                                        break
                                    case 'text':
                                        if (!hideLinkBlocks) {
                                            condition = {
                                                [`text-${idx}`]: { block_id: block.id, temp_id: block.temp_id, block: Blocks.Text(block) },
                                            }
                                        }
                                        break
                                    case 'link':
                                        if (!hideLinkBlocks) {
                                            condition = {
                                                [`link-${idx}`]: { block_id: block.id, temp_id: block.temp_id, block: Blocks.Link(block) },
                                            }
                                        }
                                        break
                                    case 'vcard':
                                        if (!hideLinkBlocks) {
                                            condition = {
                                                [`link-${idx}`]: { block_id: block.id, temp_id: block.temp_id, block: Blocks.Vcard(block) },
                                            }
                                        }
                                        break

                                }
                                return {
                                    ...conditions,
                                    ...condition,
                                }
                            }, {})
                            : {
                                signup: Blocks.Signup(),
                                links: Blocks.Link(),
                            }
                        return (
                            <section className={styles.signupForm}>
                                {isPageConsolidation && <OfferCodeBlock />}
                                {Object.values(components).map((comp, idx) => {
                                    return isPreview ? (
                                        <div className={styles.preview} key={idx}>
                                            <div
                                                className={styles.it}
                                                onClick={(event) => {
                                                    const rect = event?.currentTarget?.getBoundingClientRect()
                                                    previewHandler?.sendMessageToParent({
                                                        type: "block_clicked",
                                                        body: {
                                                            id: comp?.block_id,
                                                            temp_id: comp?.temp_id,
                                                            block_x: rect?.top,
                                                            block_y: rect?.left,
                                                            height: event?.currentTarget?.offsetHeight,
                                                            width: event?.currentTarget?.offsetWidth,
                                                            page_width: window.innerWidth,
                                                            page_height: window.innerHeight
                                                        }
                                                    })
                                                }}
                                            >

                                            </div>
                                            {comp.block}
                                        </div>
                                    ) : comp.block
                                })}
                            </section>
                        )
                    }}
                />
            </WizardSlider>
        </div>
    )
}

export default React.memo(EnterEmail)
